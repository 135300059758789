<template>
  <v-container>
    <v-row>
      <v-btn @click="clearEventCache" :loading="cacheClearing" :disabled="cacheClearing">
        Clear Event Cache
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="$store.state && $store.state.account">
        <div>
          {{`Logged in as ${$store.state.loggedInEmail}`}}
        </div>
        <div>
          {{`Logged in with Google: ${$store.state.googleUser ? true : false}`}}
        </div>
        <div>
          {{`AccountId: ${$store.state.account.accountId}`}}
        </div>
        <div>
          {{`IdentityId: ${$store.state.account.deviceId}`}}
        </div>
        <div>
          {{`Time Sync Offset: ${timeSyncOffset}ms`}}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import EventCache from "@/services/EventCache";
  import AWSClockSkewHandler from "../aws/AWSClockSkewHandler";
  export default {
    data() {
      return {
        timeSyncOffset: AWSClockSkewHandler.getOffset(),
        cacheClearing: false
      }
    },
    created() {

    },
    methods: {
      async clearEventCache() {
        this.cacheClearing = true
        await EventCache.clearCache()
        this.cacheClearing = false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
</style>


<!--license-->
<!--https://github.com/dimsemenov/PhotoSwipe-->
<!--https://github.com/ElemeFE/vue-infinite-scroll-->
<!--amazon sdk-->
<!--vue.js-->
<!--https://vuetifyjs.com/quick-start-->
