var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-btn",
            {
              attrs: {
                loading: _vm.cacheClearing,
                disabled: _vm.cacheClearing
              },
              on: { click: _vm.clearEventCache }
            },
            [_vm._v("\n      Clear Event Cache\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.$store.state && _vm.$store.state.account
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s("Logged in as " + _vm.$store.state.loggedInEmail) +
                      "\n      "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        "Logged in with Google: " +
                          (_vm.$store.state.googleUser ? true : false)
                      ) +
                      "\n      "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        "AccountId: " + _vm.$store.state.account.accountId
                      ) +
                      "\n      "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        "IdentityId: " + _vm.$store.state.account.deviceId
                      ) +
                      "\n      "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s("Time Sync Offset: " + _vm.timeSyncOffset + "ms") +
                      "\n      "
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }